<template>
  <d-container fluid>
    <pageTitle subtitle="Leverancier"
               title="Overzicht"/>
    <d-row>
      <vCard :col="8" header="Leveranciers" :fluid="true" :actions="refresh">
        <vTable :fields="fields" :actions="actions" :data="data"/>
      </vCard>
      <vCard :col="4" header="Leverancier aanmaken">
        <addSupplier @submit="eventNewSupplier"/>
      </vCard>
    </d-row>
  </d-container>
</template>

<script>

export default {
  name: 'supplierOverview',
  title: 'Leveranciers Overzicht',
  components: {
    addSupplier: () => import('@/components/forms/supplier/addSupplier')
  },
  computed: {
    refresh: {
      get() {
        return [{
          icon: '<i class="material-icons">refresh</i>',
          fn: () => {
            this.data = null
            this.getSuppliers()
          }
        }]
      }
    }
  },
  data() {
    return {
      actions: [
        {
          icon: '<i class="material-icons">visibility</i>',
          fn: (supplier) => {
            this.$router.push({name: 'dashboard/inkoop/supplier/leverancier', params: {supplier_id: supplier.id}})
          }
        }
      ],
      data: null,
      fields: [
        {
          name: 'name',
          title: 'Leverancier',
          sortField: 'name'
        },
        'actions'
      ],
    }
  },
  created() {
    this.getSuppliers()
  },
  methods: {
    async getSuppliers() {
      let response = await this.$api.supplier.list()
      this.data = response.data
    },
    eventNewSupplier(supplier) {
      this.data.push(supplier)
    }
  }
}
</script>

<style scoped>

</style>
